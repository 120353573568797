<template>
  <v-container>
    <v-layout justify-center align-center>
      <v-flex xs12 sm8 md8>
        <v-sheet color="white" elevation="0" width="100%">
          <v-card flat tile v-if="this.pdfdata.header.doc_code">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6" class="text-md-left text-center">
                  <table width="100%">
                    <tr>
                      <td>
                        <h3>{{ pdfdata.store.name }}</h3>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ pdfdata.store.address }}</td>
                    </tr>
                    <tr>
                      <td>{{ pdfdata.store.city + ", " + pdfdata.store.province }}</td>
                    </tr>
                    <tr>
                      <td>{{ pdfdata.store.mobile }}</td>
                    </tr>
                    <tr>
                      <td>Orden No. {{ pdfdata.header.doc_code }}</td>
                    </tr>
                  </table>
                </v-col>
                <v-col cols="12" md="6">
                  <table>
                    <tr>
                      <td>Nombre:</td>
                      <td>{{ pdfdata.header.party_name }}</td>
                    </tr>
                    <tr>
                      <td>Dirección :</td>
                      <td>{{ pdfdata.header.party_address }}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        {{
                          pdfdata.header.party_city + ", " + pdfdata.header.party_province
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Email :</td>
                      <td>{{ pdfdata.header.party_email }}</td>
                    </tr>
                    <tr>
                      <td>Móvil :</td>
                      <td>{{ pdfdata.header.party_phone }}</td>
                    </tr>
                  </table>
                </v-col>
                <v-col cols="12">
                Para entregar : {{pdfdata.header.delivery_date + "  @" + pdfdata.header.delivery_time}}
                </v-col>
              </v-row>

              <v-data-table
                calculate-widths
                :headers="header[$vuetify.breakpoint.xsOnly]"
                :items="pdfdata.items"
                :items-per-page="-1"
                item-key="index"
                hide-default-footer
                sort-by="name"
                mobile-breakpoint="0"
                class="table-cursor elevation-1"
                loading-text="Cargando ..."
              >
                <template v-slot:top></template>
                <template v-slot:[`item.product_name`]="{ item }">
                  <div>
                    <b class="text-uppercase">{{ item.product_name }}</b> -
                    {{
                      (parseFloat(item.pack) * item.size).toLocaleString(2) +
                      " " +
                      item.uom
                    }}
                    <span v-html="item.description" />
                    <template v-if="item.product_options">
                      <v-row>
                        <v-col
                          cols="12"
                          v-for="(value, key) in item.product_options"
                          :key="key"
                          class="pa-0"
                        >
                          <v-data-table
                            :headers="headerSelected"
                            hide-default-header
                            hide-default-footer
                            :items-per-page="-1"
                            mobile-breakpoint="0"
                            :items="
                              item.product_options[key].filter(function (rg) {
                                return rg.selected === true;
                              })
                            "
                            item-key="product_name"
                            :single-select="selecttype(item.product_options[key])"
                            dense
                          >
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </template>
                  </div>
                </template>
                <template v-slot:[`item.pack`]="{ item }">
                  {{
                    (parseFloat(item.pack) * item.size).toLocaleString(2) + " " + item.uom
                  }}
                </template>
                <template v-slot:[`item.qty`]="{ item }">
                  {{ parseFloat(item.qty) }}
                  <!-- <v-btn text fab @click="editItm(item)">
                   
                  </v-btn> -->
                </template>
                <template v-slot:[`item.uom`]="{ item }">
                  {{
                    "$ " +
                    (
                      (parseFloat(item.price) - parseFloat(item.discount)) *
                      parseFloat(item.pack)
                    ).toLocaleString(2)
                  }}
                </template>
                <template v-slot:[`item.tItem`]="{ item }" class="text-right">
                  {{ "$" + parseFloat(item.tItem).toLocaleString() }}
                </template>
                <!-- <template v-slot:[`item.actions`]="{ item }" class="text-right">
                                <v-btn icon fab @click="deleteItem(item)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </template> -->
                <template slot="body.append">
                  <tr>
                    <th>Totales</th>
                    <th class="text-right">
                      {{ sumField(pdfdata.items, "qty").toLocaleString(2) }}
                    </th>
                    <th class="text-right">
                      {{ sumField(pdfdata.items, "tItem").toLocaleString(2) }}
                    </th>
                  </tr>
                </template>
              </v-data-table>

              <v-data-table
                :headers="header_payments"
                :items="pdfdata.payments"
                :items-per-page="-1"
                item-key="code"
                v-if="paid"
                hide-default-footer
                sort-by="name"
                mobile-breakpoint="0"
                class="table-cursor mt-3"
                loading-text="Cargando ..."
              >
                <template v-slot:top></template>
                <template v-slot:[`item.payment_type`]="{ item }" class="pa-0 ma-0">
                  <b class="text-uppercase">{{ item.payment_type }}</b
                  ><br />
                  <small>{{ item.issue_date }}</small
                  ><br />
                  <small>{{ item.payment_reference }}</small>
                </template>
                <template v-slot:[`item.payment_value`]="{ item }" class="pa-0 ma-0">
                  <span>
                    {{ parseFloat(item.payment_value).toLocaleString() }}
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions v-if="paid">
              <v-btn light color="teal lighten-4" to="../Docs">Regresar</v-btn>
              <v-spacer></v-spacer>
              <v-btn light color="teal lighten-4" @click="create_pdf()"
                >Descargar PDF</v-btn
              >
            </v-card-actions>
            <v-card-actions v-else>
              <!-- <epaycoCheckout v-if="this.settings.epaycoPK && !this.paid" :publicKey="epaycoPublicKey"
                :test="this.settings.epayco_test" :bill="epaycoData" /> -->
              <v-btn text color="secondary" to="../Docs"> Regresar </v-btn>
              <v-spacer></v-spacer>
              <v-btn dark color="success" @click="checkout_wompi">Pagar Ahora</v-btn>
            </v-card-actions>
          </v-card>
          <v-alert v-model="notResponse" border="right" v-else>
            Documento no exite ...<br />
            <v-btn text to="../Docs">Regresar</v-btn>
          </v-alert>
        </v-sheet>
      </v-flex>
      <v-dialog v-model="loading_status" hide-overlay persistent max-width="480">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando ...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script type="text/javascript" src="https://checkout.wompi.co/widget.js"></script>
<script>
import { webserver } from "../services/webserver.js";
import createParty from "../utils/createParty.js";
import createWebset from "../utils/createSetting.js";
import createWompi from "../utils/wompi.js";
import jsPDF from "jspdf";
export default {
  beforeMount() {
    // var strn = this.$route.query.id.split("_");
    // if (strn[0]) {
    //   this.pulishableKey = process.env["VUE_APP_" + strn[0]];
    // }
  },
  components: {},
  data() {
    return {
      test: true,
      loading_status: false,
      clicked: false,
      valid: false,
      client_name: "",
      doc: { store: "", doc_code: "" },
      shipping_address: "",
      cardholder: "",
      nameRules: [
        (v) => !!v || "El nombre es requerido",
        (v) => v.length <= 45 || "El nombre debe tener máximo 45 caracteres",
      ],
      emailRules: [
        (v) => !!v || "Correo electrónico es requerido",
        (v) => /.+@.+/.test(v) || "Debe ser un correo electrónico válido.",
      ],
      pulishableKey: "",
      store: createParty(),
      strp: {
        name: "",
        token: "",
        email: "",
        currency_code: "cop",
        amount: 0,
        item_name: "",
        item_number: "",
      },
      pdfdata: { header: { doc_code: null }, store: {}, items: [] },
      pdfbtn: false,
      paid: false,
      header: {
        true: [
          {
            text: "Descripción",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
            width: "60%",
          },
          {
            text: "Qty",
            align: "end",
            value: "qty",
            width: "2",
          },
          {
            text: "Valor",
            align: "end",
            sortable: true,
            value: "tItem",
          },
        ],
        false: [
          {
            text: "Descripción",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
          {
            text: "Qty",
            align: "end",
            value: "qty",
            width: "2",
          },
          {
            text: "Valor",
            align: "end",
            sortable: true,
            value: "tItem",
          },
        ],
      },
      header_payments: [
        {
          text: "Forma de pago",
          align: "start",
          sortable: true,
          value: "payment_type",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "payment_value",
        },
      ],
      epaycoData: {
        name: null,
        description: null,
        invoice: null,
        currency: "COP",
        amount: 0,
        tax_base: 0,
        tax: 0,
        country: "CO",
        lang: "es",
        external: "true",
        extra1: null,
        extra2: null,
        extra3: null,
        confirmation: null,
        response: null,
        name_billing: null,
        address_billing: null,
        type_doc_billing: null,
        number_doc_billing: null,
        mobilephone_billing: null,
      },
      settings: createWebset(),
      headerSelected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
          width: 20,
        },
      ],
      bill: createWompi(),
      notResponse: false,
      docId: null,
      WompiPublicKey: null,
    };
  },
  mounted() {
    if (this.$route.params.did) {
      this.docId = this.$route.params.did;
      this.get_doc(this.docId);
    } else {
      this.$router.push("Docs");
    }
  },
  methods: {
    sumField(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    get_doc(doc_code) {
      this.loading_status = true;
      var qry = {
        table: "docs",
        doc_code: doc_code,
      };
      console.log(qry);
      webserver("get_doc", qry, (data) => {
        console.log(data);
        if (data.header) {
          this.pdfdata = data;
          var item_name = "";
          if (data.items.length > 0) {
            item_name = data.items[0].product_name;
          }
          this.store = data.store;
          if (data.store.setting) {
            this.settings = JSON.parse(data.store.setting);
            this.epaycoPublicKey = this.settings.epaycoPK;
            this.WompiPublicKey = this.settings.wompiPK;
          }
          data.items.forEach((rg) => {
            if (rg.product_options) {
              rg.product_options = JSON.parse(rg.product_options);
            } else rg.options_selected = null;
            rg.tItem = parseFloat(rg.tItem);
          });

          if (data.payments.length === 0) {
            this.paid = false;
            this.epaycoData = {
              name: item_name,
              description: "",
              invoice: data.header.doc_code,
              currency: "COP",
              amount: parseFloat(data.header.total),
              tax_base: 0,
              tax: 0,
              country: "CO",
              lang: "es",
              external: "false",
              extra1: null,
              extra2: null,
              extra3: null,
              confirmation: "https://mayorista.app/ws/epayco/response/",
              response: "https://mayorista.app/ws/epayco/response/",
              name_billing: data.header.party_name,
              address_billing: data.header.party_address,
              type_doc_billing: "cc",
              mobilephone_billing: data.header.party_phone,
              number_doc_billing: data.header.party_identification_id,
              methodsDisable: [],
            };
            this.bill = {
              currency: "COP",
              amountInCents: parseInt(data.header.total * 100),
              reference: data.header.doc_code,
              publicKey: this.WompiPublicKey,
              redirectUrl: "https://mayorista.app/checkout/?did=" + data.header.doc_code, // Opcional
              customerData: {
                // Opcional
                email: data.header.party_email,
                fullName: data.header.party_name,
                phoneNumber: data.header.party_phone,
                phoneNumberPrefix: "+57",
                // legalId: '123456789',
                // legalIdType: 'CC'
              },
              shippingAddress: {
                // Opcional
                addressLine1: data.header.party_address,
                city: data.header.party_city,
                phoneNumber: data.header.party_phone,
                region: "Cundinamarca",
                country: "CO",
              },
            };
            // console.log(this.bill);
          } else {
            this.paid = true;
            this.pdfdata = data;
            this.pdfbtn = true;
          }
        } else {
          this.notResponse = true;
          this.pdfbtn = false;
        }
        this.loading_status = false;
      });
    },
    create_pdf() {
      var doc = this.pdfdata;
      if (doc.header) {
        var pdfName = doc.header.doc_code;
        var pdf = new jsPDF({
          orientation: "p",
          unit: "mm",
          format: [80, 200],
        });

        var LS = 5;
        var y = 20;
        var x = 5;
        pdf.setFontType = "bold";
        pdf.setFontSize(10);
        pdf.text(40, y, doc.store.name, "center");
        pdf.setFontType = "normal";
        pdf.setFontSize(8);
        y += LS;
        pdf.text(40, y, doc.store.address, "center");
        y += LS;
        pdf.text(40, y, doc.store.city + ", " + doc.store.province, "center");
        y += LS;
        y += LS;
        pdf.text(5, y, " # " + doc.header.doc_code, "left");
        y += LS;
        pdf.text(5, y, "Fecha: " + doc.header.issue_date, "left");
        y += LS;
        y += 2;
        pdf.line(5, y, 75, y);
        y += LS;
        y += LS;
        pdf.setFontSize(10);
        pdf.text(5, y, doc.header.party_name, "left");
        pdf.setFontType = "normal";
        pdf.setFontSize(8);
        y += LS;
        pdf.text(5, y, doc.header.party_address, "left");
        y += LS;
        pdf.text(5, y, doc.header.party_city, "left");

        y += LS;
        pdf.text(5, y, doc.header.party_email, "left");
        y += LS;
        pdf.text(5, y, doc.header.party_phone, "left");
        y += LS;

        y += LS;
        pdf.setFontSize(8);
        pdf.setFontType = "normal";
        pdf.text(x, y, "Qty");
        x = 40;
        pdf.text(x, y, "Product", "center");
        x = 75;
        pdf.text(x, y, "Price", "right");
        y += 2;
        pdf.line(5, y, 75, y);

        doc.items.forEach((row) => {
          y += LS;
          x = 5;
          pdf.text(x, y, row.qty.toLocaleString(), "center");
          x = 10;
          pdf.text(x, y, row.product_name);
          x = 75;
          pdf.text(
            x,
            y,
            "$ " + parseFloat(row.price).toFixed(2).toLocaleString(2),
            "right"
          );
        });

        y += 2;
        pdf.line(5, y, 75, y);
        y += LS;
        x = 5;
        pdf.setFontType = "bold";
        pdf.text(x, y, "Sub-Total");
        x = 75;
        pdf.text(x, y, doc.header.total.toLocaleString(2), "right");
        y += LS;
        x = 5;
        pdf.text(x, y, "Impuesto");
        x = 75;
        pdf.text(x, y, parseFloat(doc.header.tax).toFixed(2), "right");
        y += LS;
        x = 5;
        pdf.text(x, y, "TOTAL");
        x = 75;
        pdf.text(
          x,
          y,
          (parseFloat(doc.header.total) + parseFloat(doc.header.tax)).toLocaleString(2),
          "right"
        );
        y += 2;
        pdf.line(5, y, 75, y);

        pdf.save(pdfName + ".pdf");
      }
    },
    checkout_wompi() {
      var checkout = new WidgetCheckout(this.bill);
      checkout.open((result) => {
        var transaction = result.transaction;
        console.log("Transaction ID: ", transaction.reference);
        // console.log("Transaction ID: ", transaction.id);
        // console.log("Transaction object: ", transaction.redirectUrl);
        // // this.$forceUpdate();
        // //this.$router.push(transaction.redirectUrl);
        this.get_doc(transaction.reference);
      });
    },
  },
  watch: {},
};
</script>

<style></style>
